<template>
  <div>
    <div class="columns is-multiline first-part">
      <div class="column is-12 container-img">
        <img class="image-header" :src='"../assets/logo_partner.png"' />
      </div>
      <div class="column is-8 is-offset-2 mb-4">
        <h1 class="title is-size-1 has-text-white has-text-centered has-text-weight-bold">¡Bienvenidos/as al Concurso
          Jóvenes Talentos de Relato Corto!</h1>
      </div>
      <div class="column is-12">
        <div class="columns is-multiline">
          <div class="column is-5 text-left">
            <p class="has-text-white has-text-weight-bold is-size-4 intro-text">
              Historias genuinas, protagonistas valientes, finales sorprendentes... ¡Ha llegado el momento de dar vida a
              las palabras y desplegar las alas de la imaginación!
            </p>

            <div class="columns is-multiline links">
              <div class="column is-6">
                <a class="has-text-white has-text-weight-bold is-size-5" href="/CJT64 - Autorización.pdf"
                  download="CJT64 - Autorización.pdf">Autorización castellano</a>
              </div>
              <div class="column is-5 is-offset-1">
                <a class="has-text-white has-text-weight-bold is-size-5" href="/CJT16_GL.pdf"
                download="CJT16_GL.pdf">Bases Legales Galicia</a>
              </div>
              <div class="column is-6">
                <a class="has-text-white has-text-weight-bold is-size-5" href="/CJT64 - Términos y Condiciones.pdf"
                  download="CJT64 - Términos y Condiciones.pdf">Bases Legales castellano</a>
              </div>
              <div class="column is-5 is-offset-1">
                <a class="has-text-white has-text-weight-bold is-size-5" href="/KONTAKIZUN LABURREN TALENTU GAZTEEN 64.pdf"
                  download="KONTAKIZUN LABURREN TALENTU GAZTEEN 64.pdf">Bases Legales Euskera</a>
              </div>
              <div class="column is-6">
                <a class="has-text-white has-text-weight-bold is-size-5"
                  href="/Termes i Condicions PRB - CATALUNYA y BALEARES.pdf"
                  download="Termes i Condicions PRB - CATALUNYA y BALEARES.pdf">Bases Legales Cataluña y Baleares</a>
              </div>
              <div class="column is-5 is-offset-1">
                <a class="has-text-white has-text-weight-bold is-size-5"
                  href="/Termes i Condicions PRB - ANDORRA Curs 24-25.pdf"
                  download="Termes i Condicions PRB - ANDORRA Curs 24-25.pdf">Bases Legales Andorra</a>
              </div>
            </div>
          </div>
          <div class="column is-7 pl-5 pr-5">
            <div class="columns is-multiline banner-inscripcion">
              <div class="column is-6" style="align-items: center; display: flex;">
                <img class="logo-concurso" src="../assets/header/logo_general.png" />
              </div>
              <div class="column is-6 text-left pl-6 pr-6">
                <p class="pt-6 intro-text is-size-3 has-text-black has-text-centered has-text-weight-bold">
                  ¡YA TENEMOS FECHAS PARA LAS PRUEBAS ESCRITAS!
                </p>
                <p class="intro-text is-size-5 has-text-black">
                  - 6 y 13 de marzo: lenguas cooficiales
                  <br />
                  - 7 y 14 de marzo: castellano
                </p>

                <button @click="goToRegister()" class="button mt-3 has-text-weight-bold mb-6 hover-btn"
                  style="text-transform: uppercase;">Inscripción</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-multiline second-part">
      <div class="column is-2">
        <img class="rios-tinta" :src='"../assets/landing/rios-tinta.webp"' />
      </div>
      <div class="column is-10">
        <h2 class="subtitle is-size-3 has-text-black has-text-centered has-text-weight-bold">La voz del concurso Jóvenes
          Talentos, seis décadas de creación</h2>

        <p class="mb-5 is-size-5 text-description">
          Desde 1961, el Concurso Jóvenes Talentos ha invitado a los jóvenes a desplegar las alas de su imaginación,
          volando hacia mundos desconocidos y compartiendo historias únicas. A través de la creación literaria, estos
          jóvenes enfrentan sus propios miedos y descubren que ellos también son protagonistas de sus aventuras,
          demostrando que solo un folio es suficiente para transformar lo imposible en algo extraordinario.

          <br />
          <br />

          Porque cuando las palabras dan vida a los sueños, los horizontes de la imaginación se desvanecen y así lo ha
          demostrado el Concurso Jóvenes Talentos, siendo un espacio de creatividad, forjando seis décadas de creación.
        </p>

        <h2 class="subtitle is-size-3 has-text-black has-text-centered has-text-weight-bold" style="padding-top: 30px;">
          ¡Que corran ríos de tinta!</h2>

        <p class="is-size-5 text-description">
          En marzo se celebrará la prueba escrita online simultánea en toda España, donde los participantes tendrán que
          demostrar su talento escribiendo un relato corto basándose en un estímulo creativo que descubrirán al inicio.
        </p>
      </div>
    </div>
    <div class="columns is-multiline third-part mb-4">
      <div class="column is-12">
        <p class="is-size-5">
          Fases del concurso:
        </p>
      </div>

      <div class="column is-12 pl-2 pr-2">
        <div class="fase">
          <p class="has-text-weight-bold is-size-5 mb-1">
            Fase provincial:
          </p>

          <p class="is-size-5">
            Se elegirá un ganador por provincia. En el caso de las comunidades autónomas uniprovinciales, se elegirán
            dos ganadores.
          </p>
        </div>
      </div>

      <div class="column is-12 pl-2 pr-2">
        <div class="fase">
          <p class="has-text-weight-bold is-size-5 mb-1">
            Fase autonómica:
          </p>

          <p class="is-size-5">
            Estos relatos pasarán a la fase autonómica, en la que un jurado autonómico elige el ganador de cada
            comunidad, así como los ganadores de las lenguas cooficiales (catalán de Cataluña, catalán de Islas
            Baleares, gallego y euskera).
          </p>
        </div>
      </div>

      <div class="column is-12 pl-2 pr-2">
        <div class="fase">
          <p class="has-text-weight-bold is-size-5 mb-1">
            Fase nacional:
          </p>

          <p class="is-size-5">
            Los 17 mejores relatos en castellano pasan a la fase nacional, junto con los ya ganadores nacionales de las
            lenguas cooficiales. Para el concurso en castellano, un jurado estatal integrado por reconocidos
            periodistas, escritores y novelistas, elige a los ganadores de su lengua (1er, 2ª y 3er premio nacional).
          </p>
        </div>
      </div>
    </div>
    <div class="columns is-multiline fourth-part">
      <div class="column is-12 premios">
        <h2 class="subtitle is-size-3 has-text-black has-text-centered has-text-weight-bold">PREMIOS</h2>
        <div class="is-flex is-justify-content-center">
          <hr />
        </div>
        <div class="is-flex image-text-premios">
          <img :src='"../assets/landing/premio1.webp"' />

          <p class="is-size-5 premios-text">
            El <b>mejor premio</b> será para todos, ya que os lleváis la <b>experiencia de participar y haber superado
              el desafío que produce el folio en blanco</b>. ¡Enhorabuena!
          </p>
        </div>
        <div class="is-flex is-justify-content-center">
          <hr />
        </div>
        <div class="is-flex image-text-premios">
          <img :src='"../assets/landing/premio2.webp"' />

          <p class="is-size-5 premios-text">
            Otros <b>premios</b> para los relatos seleccionados:
            <br />
            -Un obsequio para los alumnos que ganen la fase provincial.
            <br />
            -Estancia de una noche en Madrid y asistencia a la Gala Nacional del Concurso para los alumnos y profes que
            ganen la fase autonómica.
            <br />
            -Los siete ganadores nacionales recibirán un curso de escritura creativa online.
          </p>
        </div>
        <div class="is-flex is-justify-content-center">
          <hr />
        </div>
      </div>
    </div>
    <div class="columns is-multiline fifth-part">
      <div class="column is-12">
        <h2 class="subtitle is-size-3 has-text-white has-text-centered has-text-weight-bold">CONTACTO</h2>
        <img class="logo-contacto" :src='"../assets/landing/contacto.webp"' />
      </div>
      <div class="column is-12">
        <p class="is-size-5 has-text-white line-spacing">
          <b>Oficina técnica del concurso:</b>
          <br />
          +34 634 532 726
          <br />
          <a class="has-text-white" href="mailto:cjtfundation@gmail.com">cjtfundation@gmail.com</a>
        </p>
      </div>
      <div class="column is-12 mt-2 mb-2">
        <p class="is-size-5 has-text-white">
          Si necesitas más información o cualquier tipo de ayuda, puedes contactar con el responsable local de
          Coca‑Cola.
        </p>
      </div>
      <div class="column is-6">
        <p class="is-size-5 has-text-white has-text-weight-bold mb-2">
          GALICIA
        </p>

        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 40px;">
          <li>674 045 489 concursojovenestalentos.galicia@gmail.com</li>
        </ul>

        <p class="is-size-5 has-text-white has-text-weight-bold mb-2">
          PRINCIPADO DE ASTURIAS
        </p>

        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 40px;">
          <li>606951826 persuasion@persuasion.es</li>
        </ul>

        <p class="is-size-5 has-text-white has-text-weight-bold mb-2">
          CANTABRIA
        </p>

        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 40px;">
          <li>606951826 persuasion@persuasion.es</li>
        </ul>

        <p class="is-size-5 has-text-white has-text-weight-bold mb-2">
          PAÍS VASCO
        </p>

        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 40px;">
          <li>606951824 secretaria@persuasion.es</li>
        </ul>

        <p class="is-size-5 has-text-white has-text-weight-bold mb-2">
          NAVARRA
        </p>

        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 40px;">
          <li>606951826 persuasion@persuasion.es</li>
        </ul>

        <p class="is-size-5 has-text-white has-text-weight-bold mb-2">
          CASTILLA Y LEÓN
        </p>

        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 40px;">
          <li class="mb-1"><b>Ávila:</b> 651166138 claudia@uno-comunicacion.com</li>
          <li class="mb-1"><b>Burgos:</b> 606 951 824 secretaria@persuasion.es</li>
          <li class="mb-1"><b>León:</b> 606951826 persuasion@persuasion.es</li>
          <li class="mb-1"><b>Palencia:</b> 606 951 826 persuasion@persuasion.es</li>
          <li class="mb-1"><b>Salamanca:</b> 651166138 claudia@uno-comunicacion.com</li>
          <li class="mb-1"><b>Segovia:</b> 651166138 claudia@uno-comunicacion.com</li>
          <li class="mb-1"><b>Soria:</b> 606 951 824 secretaria@persuasion.es</li>
          <li class="mb-1"><b>Valladolid:</b> 651166138 claudia@uno-comunicacion.com</li>
          <li class="mb-1"><b>Zamora:</b> 606 951 826 persuasion@persuasion.es</li>
        </ul>

        <p class="is-size-5 has-text-white has-text-weight-bold mb-2">
          LA RIOJA
        </p>

        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 40px;">
          <li>606 951 824 secretaria@persuasion.es</li>
        </ul>

        <p class="is-size-5 has-text-white has-text-weight-bold mb-2">
          ARAGÓN
        </p>

        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 40px;">
          <li>606951824 secretaria@persuasion.es</li>
        </ul>

        <p class="is-size-5 has-text-white has-text-weight-bold mb-2">
          CATALUÑA
        </p>

        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 40px;">
          <li>935 801 916 carme.concurs@sp-maracana.com</li>
        </ul>

        <p class="is-size-5 has-text-white has-text-weight-bold mb-2">
          ANDORRA
        </p>

        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 40px;">
          <li>+376 741 260 Inaki_Rubio@govern.ad / david_galvez@govern.ad </li>
        </ul>
      </div>
      <div class="column is-6">
        <p class="is-size-5 has-text-white has-text-weight-bold mb-2">
          COMUNIDAD DE MADRID
        </p>

        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 40px;">
          <li>670415888 ana@uno-comunicacion.com</li>
        </ul>

        <p class="is-size-5 has-text-white has-text-weight-bold mb-2">
          EXTREMADURA
        </p>

        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 40px;">
          <li class="mb-1"><b>Cáceres</b>: 651166138 claudia@uno-comunicacion.com</li>
          <li class="mb-1"><b>Badajoz</b>: 692729198 guest.es.sev1@ccep.com</li>
        </ul>

        <p class="is-size-5 has-text-white has-text-weight-bold mb-2">
          CASTILLA LA-MANCHA
        </p>

        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 40px;">
          <li>603830348 amalia@uno-comunicacion.com</li>
        </ul>

        <p class="is-size-5 has-text-white has-text-weight-bold mb-2">
          COMUNIDAD VALENCIANA
        </p>

        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 40px;">
          <li>935 801 916 carme.concurs@sp-maracana.com</li>
        </ul>

        <p class="is-size-5 has-text-white has-text-weight-bold mb-2">
          ISLAS BALEARES
        </p>

        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 40px;">
          <li>935 801 916 carme.concurs@sp-maracana.com</li>
        </ul>

        <p class="is-size-5 has-text-white has-text-weight-bold mb-2">
          REGIÓN DE MURCIA
        </p>

        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 40px;">
          <li>661452860 vtacoronte.ext@ccep.com</li>
        </ul>

        <p class="is-size-5 has-text-white has-text-weight-bold mb-2">
          ANDALUCÍA
        </p>

        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 40px;">
          <li class="mb-1"><b>Almería</b>: 661452860 vtacoronte.ext@ccep.com</li>
          <li class="mb-1"><b>Cádiz</b>: 692729198 guest.es.sev1@ccep.com</li>
          <li class="mb-1"><b>Córdoba</b>: 692729198 guest.es.sev1@ccep.com</li>
          <li class="mb-1"><b>Granada</b>: 661452860 vtacoronte.ext@ccep.com</li>
          <li class="mb-1"><b>Huelva</b>: 692729198 guest.es.sev1@ccep.com</li>
          <li class="mb-1"><b>Jaén</b>: 692729198 guest.es.sev1@ccep.com</li>
          <li class="mb-1"><b>Málaga</b>: 661452860 vtacoronte.ext@ccep.com</li>
          <li class="mb-1"><b>Sevilla</b>: 692729198 guest.es.sev1@ccep.com</li>
        </ul>

        <p class="is-size-5 has-text-white has-text-weight-bold mb-2">
          ISLAS CANARIAS
        </p>

        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 40px;">
          <li>661452860 vtacoronte.ext@ccep.com</li>
        </ul>

        <p class="is-size-5 has-text-white has-text-weight-bold mb-2">
          CEUTA Y MELILLA
        </p>

        <p class="is-size-5 has-text-white has-text-weight-bold">
          CIUDAD AUTÓNOMA DE CEUTA
        </p>
        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 10px;">
          <li>661452860 vtacoronte.ext@ccep.com</li>
        </ul>
        <p class="is-size-5 has-text-white has-text-weight-bold">
          CIUDAD AUTÓNOMA DE MELILLA
        </p>
        <ul class="is-size-5 has-text-white pl-4" style="list-style-type: disc; margin-bottom: 40px;">
          <li>661452860 vtacoronte.ext@ccep.com</li>
        </ul>
      </div>
    </div>
    <div class="columns is-multiline sixth-part">
      <div class="column is-12">
        <a href="/Ganadores 63.ª edición.pdf" download="Ganadores 63.ª edición.pdf">
          <button class="button hover-btn">
            Descubre los ganadores de la 63.ª edición
            <font-awesome-icon class="ml-3" :icon="['fas', 'arrow-right']" />
          </button>
        </a>
      </div>
    </div>
    <div class="columns is-multiline footer">
      <div class="column is-10 is-offset-1 is-flex is-justify-content-space-between	">
        <img :src='"../assets/logo_partner.png"' />
      </div>
      <div class="column is-10 is-offset-1">
        <hr />
      </div>
      <div class="column is-10 is-offset-1 is-flex is-justify-content-space-between	">
        <p class="is-size-6 has-text-weight-bold pl-5" style="color: #d2d2d2;">
          ¿NECESITA AYUDA?
        </p>

        <div class="is-flex">
          <div class="social-media mr-3">
            <a href="https://www.facebook.com/CocaColaCoEspana/" target="_blank"><font-awesome-icon
                :icon="['fab', 'facebook-f']" /></a>
          </div>
          <div class="social-media mr-3">
            <a href="https://www.youtube.com/CocaColaJourneyES" target="_blank"><font-awesome-icon
                :icon="['fab', 'youtube']" /></a>
          </div>
          <div class="social-media mr-3">
            <a href="https://x.com/CocaColaCo_es" target="_blank"><font-awesome-icon :icon="['fab', 'x-twitter']" /></a>
          </div>
          <div class="social-media">
            <a href="https://www.instagram.com/cocacolaco_es/?hl=es%C3%A7" target="_blank"><font-awesome-icon
                :icon="['fab', 'instagram']" /></a>
          </div>
        </div>
      </div>
      <div class="column is-10 is-offset-1 is-flex is-justify-content-space-between	">
        <p class="is-size-5 has-text-weight-bold has-text-white pl-5">
          <a class="has-text-white mr-5" href="/FAQS_Profes 64.ª CJT.pdf" download="FAQS_Profes 64.ª CJT.pdf">Preguntas
            frecuentes</a>
        </p>
      </div>
      <div class="column is-10 is-offset-1">
        <hr />
      </div>
      <div class="column is-10 is-offset-1">
        <p class="is-size-6 has-text-white has-text-right">
          © 2025 The Coca-Cola Company. Todos los derechos reservados.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "landing",
    components: {
    },
    data() {
      return {
      };
    },
    methods: {
      goToRegister() {
        this.$router.push("/login");
      },
      downloadPDF(pdfName) {
        const pdfUrl = require(`@/assets/landing/${pdfName}.pdf`);

        const link = document.createElement('a');
        link.href = pdfUrl;
        link.setAttribute('download', `${pdfName}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
</script>

<style scoped lang="scss">
  @import "../styles/variables.scss";

  .first-part{
    min-height: 100vh;
    background: linear-gradient(to bottom, #f40008 80%, white);
    padding: 30px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 20vh;
    
    .container-img {
      height: fit-content;

      .image-header{
        max-height: 50px;
      }
    }

    .intro-text{
      line-height: 1.4;
    }

    .links {
      a{
        text-decoration: underline;
      }
    }

    .text-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .banner-inscripcion {
      min-height: 380px;
      padding-top: 20px;
      padding-bottom: 20px;
      border-radius: 25px;
      background-color: white;

      img {
        max-height: 320px;
      }

      button{
        color: white;
        background-color: #f40008;
        border: none;
        border-radius: 10px;
      }
    }
  }

  .second-part {
    padding: 30px;
    padding-left: 60px;
    padding-right: 60px;
    background-color: white;
    padding-bottom: 0px;

    .rios-tinta {
      min-height: 300px;
    }

    .text-description {
      line-height: 1.3;
    }
  }

  .third-part {
    padding: 30px;
    padding-left: 60px;
    padding-right: 60px;
    background-color: white;

    .fase {
      padding: 16px;
      border-radius: 15px;
      background-color: #ededed;
    }
  }

  .fourth-part {
    padding: 50px;
    padding-left: 70px;
    padding-right: 70px;
    background-color: #f40008;

    .premios {
      background-color: white;
      border-radius: 25px;
      padding: 16px;
      padding-left: 30px;
      padding-right: 30px;

      img {
        max-height: 70px;
        margin-right: 25px;
      }

      hr {
        width: 300px;
        height: 1px;
        background-color: #f40008;
      }

      .image-text-premios {
        align-items: center
      }
    }

    .premios-text {
      line-height: 1.4;
    }
  }

  .fifth-part {
    padding: 30px;
    padding-left: 60px;
    padding-right: 60px;
    background-color: black;

    .logo-contacto{
      right: 30px;
      margin-top: -130px;
      position: absolute;
      max-height: 200px;
    }

    .line-spacing {
      line-height: 1.6;
    }

    a{
      text-decoration: underline;
    }
  }

  .sixth-part {
    padding: 30px;
    padding-left: 60px;
    padding-right: 60px;
    background-color: white;

    button {
      color: white;
      background-color: #f40008;
      border: none;
      border-radius: 10px;
      width: 100%;
      font-weight: bold;
      height: 50px;
    }
  }

  .footer {
    padding: 30px;
    padding-left: 60px;
    padding-right: 60px;
    background-color: black;

    img {
      max-height: 30px;
    }

    hr {
      width: 100%;
      height: 1px;
      background-color: white;
    }

    .location {
      padding: 8px;
      padding-left: 14px;
      padding-right: 14px;
      border: solid 1px white;
      border-radius: 25px;
    }

    .social-media{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 28px;
      width: 28px;
      border: solid 1px white;
      border-radius: 50%;

      a {
        color: white;
      }
    }
  }

  .hover-btn:hover {
    background-color: #c90007 !important;
  }
</style>